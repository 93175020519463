import React from 'react';
import { createRoot } from 'react-dom/client';

import LanguageSelector from '../components/LanguageSelector';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('language-selector-app');
  const locales = JSON.parse(node.dataset.availableLocales);
  const props = {
    availableLocales: locales
      .map(([group, vals]) => ({ [group]: vals }))
      .reduce((acc, val) => ({ ...acc, ...val }), {}),
    chooseLanguageString: node.dataset.chooseLanguageString,
    currentLocale: node.dataset.currentLocale,
    dir: node.dataset.dir,
    targetPathTemplate: node.dataset.targetPathTemplate,
  };

  createRoot(node).render(<LanguageSelector {...props} />);
});
